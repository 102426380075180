import { DailyReport } from "./components/DailyReport";
import { DietProgram } from "./components/DietProgram";

const AppRoutes = [
	{
		index: true,
		element: <DailyReport />
	},
	{
		path: '/daily-report',
		element: <DailyReport />
	},
	{
		path: '/menu',
		element: <DietProgram />
	}
];

export default AppRoutes;

import React, { Component/*, useEffect, useState, useRef*/ } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
//import m from 'materialize-css/dist/js/materialize.min.js';

import './custom.css';
//import 'materialize-css/dist/css/materialize.min.css';

export default class App extends Component {
//export default function App() {
  static displayName = App.name;

  /*  useEffect(() => {
        //
        this.m = window.m;
        this.m.autoinit();
    }, [])
    */
  render() {
    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </Layout>
    );
  }
}

import React, { useEffect, useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import EditIngredientDialog from './EditIngredientDialog';

import './DailyReport.css';

export function DailyReport() {
	const [isMenuLoading, setIsMenuLoading] = useState(true);
	const [isDishesLoading, setIsDishesLoading] = useState(true);

	const [date, setDate] = useState(new Date().toJSON().slice(0, 10));
	const [menuGroups, setMenuGroups] = useState([]);// TODO: Remove groups word
	//let menuGroupsData = [];

	const [dishes, setDishes] = useState([]);
	const [newDish, setNewDish] = useState('');

	const [selectedDishIndex, setSelectedDishIndex] = useState(-1);
	const [selectedMenuOption, setSelectedMenuOption] = useState(null);
	const [selectedMenuOptionName, setSelectedMenuOptionName] = useState('');
	const [selectedMenuOptionWeight, setSelectedMenuOptionWeight] = useState('');
	//const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
	//const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

	const editIngredientDialogRef = useRef(null);


	useEffect(() => {
		loadAll();
	}, [])

	const loadAll = () => {
		// Load Menu
		fetch('/api/dietprogram/getall', {
			method: 'POST'
		})
		.then(response => response.json())
		.then(data => {
			// Load Daily Plan
			loadDailyDishes(date, data);
			setIsMenuLoading(false);
		})
		.catch(error => console.log("Error detected: " + error));
	}

	const loadDailyDishes = (ldate, menu) => {
		const formData = new FormData();
		formData.append("date", ldate);
		fetch('/api/dailyreport/getalldishes', {
			method: 'POST',
			body: formData
		})
		.then(response => response.json())
		.then(dishes => {
			setDishes(dishes);
			setMenuGroups(calculateWhatHaveLeft(menu, dishes));
			setIsDishesLoading(false);
		})
		.catch(error => console.log("Error detected: " + error));
	}

	const addNewDish = () => {
		const formData = new FormData();
		formData.append("name", newDish);
		formData.append("date", date);
		
		fetch('/api/dailyreport/adddish', {
			method: 'POST',
			body: formData
		})
		.then(response => response.json())
		.then(data => {
			data.ingredients = [];
			dishes.push(data);
			setNewDish('');
			setSelectedDishIndex(dishes.length - 1);
		})
		.catch(error => console.log("Error detected: " + error));
	}

	const selectDish = (dishIndex) => (event) => {
		setSelectedDishIndex(dishIndex);
	}

	const selectMenuOption = (groupIndex, optionIndex) => (event) => {
		setSelectedMenuOption(menuGroups[groupIndex].options[optionIndex]);
		setSelectedMenuOptionName(menuGroups[groupIndex].options[optionIndex].name);
		setSelectedMenuOptionWeight(menuGroups[groupIndex].options[optionIndex].weightLeft);
	}

	const addDishIngredient = () => {
		
		try {
			var enteredWeight = Math.round(eval(selectedMenuOptionWeight));
			if (!Number.isInteger(enteredWeight)) {
				alert('Щось не так з вагою!');
				return;
			}
		} catch (e) {
			console.log(e.message);
			alert('Щось не так з вагою!');
			return;
		}
		
	
		// Borrow from other categories
		if (enteredWeight > selectedMenuOption.weightLeft) {

			// find where to borrow
			let borrowArr = [];
			menuGroups.map(group => {
				group.options.map(option => {
					//console.log(option.name);
					if (option.name == selectedMenuOption.name && option.id != selectedMenuOption.id && option.weightLeft > 0) {
						borrowArr.push(option);
					}
				});
			});

			enteredWeight -= selectedMenuOption.weightLeft;

			// loop borrow array
			for (var i = 0; i < borrowArr.length; i++) {
				if (enteredWeight > borrowArr[i].weightLeft) {
					// borrow max what you can
					addDishIngredientRequest(dishes[selectedDishIndex].id, borrowArr[i], borrowArr[i].weightLeft);
					enteredWeight -= borrowArr[i].weightLeft;
				} else {
					// or only what you need
					addDishIngredientRequest(dishes[selectedDishIndex].id, borrowArr[i], enteredWeight);
					enteredWeight = 0;
					break;
				}
			}

			enteredWeight += selectedMenuOption.weightLeft;
		}
		
		if (enteredWeight > 0) addDishIngredientRequest(dishes[selectedDishIndex].id, selectedMenuOption, enteredWeight);

		setSelectedMenuOption(null);
		setSelectedMenuOptionName('');
		setSelectedMenuOptionWeight('');

	}

	const addDishIngredientRequest = (dishId, menuOption, weight) => {
		const formData = new FormData();
		formData.append("ReportDishId", dishId);
		formData.append("MenuOptionId", menuOption.id);
		formData.append("MenuGroupId", menuOption.menuGroupId);
		formData.append("TargetWeight", menuOption.weight);
		formData.append("UsedWeight", weight);
		formData.append("name", selectedMenuOptionName);

		fetch('/api/dailyreport/dish/ingredient/add', {
			method: 'POST',
			body: formData
		})
			.then(response => response.json())
			.then(data => {
				dishes[selectedDishIndex].ingredients.push(data);
				const updatedDishes = dishes.map(m => m);

				setMenuGroups(calculateWhatHaveLeft(menuGroups, updatedDishes));
				setDishes(updatedDishes);
			})
			.catch(error => console.log("Error detected: " + error));
	}

	const editIngredient = (id) => () => {
		editIngredientDialogRef.current.handleShow(id);
	}

	const calculateWhatHaveLeft = (menu, dishes) => {
		return menu.map(group => {
			let percent = 0;
			dishes.map(dish => {
				//if (dish.ingredients != null && dish.ingredients != undefined) { // TODO: Not sure if neaded
					dish.ingredients.map(ingredient => {
						if (group.id == ingredient.menuGroupId) {
							percent += ingredient.usedWeight / ingredient.targetWeight;  // TODO: Zero div validation
						}
					});
				//}
			});

			group.usedPercent = 1 - percent;

			group.options = group.options.map(option => {
				option.weightLeft = Math.round(option.weight * group.usedPercent);
				return option;
			});			

			return group;
		});
	}

	const copyReport = () => {
		let result = '';

		dishes.map((dish, index) => {
			result += (index + 1) + "\n";
			dish.ingredients.map(ingredient => {
				result += " " + ingredient.name + ": " + ingredient.usedWeight + "г \n";
			});

			result += "\n";
		});

		navigator.clipboard.writeText(result);
	}

	return (
		<div>
			<div>
				{ /* Date Section */ }
				<div>
					<InputGroup className="mb-3">
						<Form.Control
							type="date"
							name="duedate"
							placeholder="Due date"
							value={date}
							onChange={(e) => { setDate(e.target.value); setIsDishesLoading(true); loadDailyDishes(e.target.value, menuGroups); /*setMenuGroups(calculateWhatHaveLeft());*/ } }
						/>
						<Button variant="outline-secondary" onClick={copyReport}>
							Скопіювати звіт
						</Button>
					</InputGroup>
				</div>
				<br />

				{ /* Dishes Section */}
				{isDishesLoading ?
					(<div>Loading...</div>) :
					(<div>
						<Form>
							<ListGroup>
								{dishes.map((dish, dishIndex) => (
									<ListGroup.Item key={'gid-' + dish.id}>
										<Form.Check type='radio' key={'did-' + dish.id} name='dishes-group' label={dish.name} onChange={selectDish(dishIndex)} checked={selectedDishIndex == dishIndex } />

										<ul>
											{dish.ingredients != null && dish.ingredients != undefined &&
												dish.ingredients.map((ingredient, ingredientIndex) => (
													<li key={ingredient.id}><a onClick={editIngredient(ingredient.id)}>
														{ingredient.groupName + ') ' + ingredient.name + ': ' + ingredient.usedWeight}
														&nbsp;&nbsp;<img width="15px" src="/img/edit.png" /></a>
													</li>
												))
											}
										</ul>
									</ListGroup.Item>
								))}

								<ListGroup.Item key='new-dish'>
									<InputGroup className="mb-3">
										<Form.Control placeholder="Нова страва" aria-label="Нова страва" type="text" value={newDish} onChange={e => setNewDish(e.target.value)} />
										<Button variant="outline-secondary" onClick={addNewDish}>
											Додати
										</Button>
									</InputGroup>
								</ListGroup.Item>
							</ListGroup>
						</Form>
						<br />
					</div>)}

				{ /* Meny Section */}
				{isMenuLoading ?
					(<div>Loading...</div>) :
					(<div>
						<Form>
							<Tabs defaultActiveKey="profile" id="group-tabs" className="mb-3" >
								{menuGroups.map((group, groupIndex) => (

									<Tab eventKey={group.name} key={'gid-' + group.id} title={group.name + " " +Math.round(group.usedPercent * 100) + "%"}>
										{group.options.map((option, optionIndex) => (
											<Form.Check
												type='radio'
												key={'oid-' + option.id}
												id={'oid-' + option.id}
												name='group1'
												label={option.name + ': ' + option.weightLeft}
												onChange={selectMenuOption(groupIndex, optionIndex)}
											/>
										))}
									</Tab>
								))}
							</Tabs>
						</Form>
						<br /><br /><br /><br /><br /><br /><br />
					</div>)}

				{ /* Dishes Assembling Section */}
				<div className="fixed-bottom">
					<Navbar color="dark">
						<Container>
							<div className="col-sm">
								{selectedMenuOption != null &&
								(<div>
									<Form.Control placeholder="Продукт" aria-label="Продукт" type="text" value={selectedMenuOptionName} onChange={e => setSelectedMenuOptionName(e.target.value)} />
									<Form.Control placeholder="Вага" aria-label="Вага" type="text" value={selectedMenuOptionWeight} onChange={e => setSelectedMenuOptionWeight(e.target.value)}   />
									
								</div>)}
								{selectedDishIndex != -1 &&
									(<div></div>)
								}
							</div>
							<div className="col-sm">
								{selectedMenuOption != null && selectedDishIndex != -1 &&
									(<Button variant="outline-secondary" onClick={addDishIngredient}>Додати до {dishes[selectedDishIndex].name}</Button>)
								}
							</div>
						</Container>
					</Navbar>
				</div>
			</div>

			<EditIngredientDialog ref={editIngredientDialogRef} loadAll={ loadAll } ></EditIngredientDialog>
		</div>
	);
}
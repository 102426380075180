import React, { useEffect, useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

import EditGroupDialog from './EditGroupDialog';


export function DietProgram() {
	const [newGroupName, setNewGroupName] = useState('');
	const [menuGroups, setMenuGroups] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const editGroupDialogRef = useRef(null);

	useEffect(() => {
		loadAll();
	}, [])

	const loadAll = () => {
		fetch('/api/dietprogram/getall', {
			method: 'POST'
		})
		.then(response => response.json())
		.then(data => {
			setMenuGroups(data);
			setIsLoading(false);
		})
		.catch(error => console.log("Error detected: " + error));
	}

    const addNewGroup = () => {
		const formData = new FormData();
		formData.append("name", newGroupName);

		fetch('/api/dietprogram/group/add', {
			method: 'POST',
			body: formData
		})
		.then(response => response.json())
		.then(data => {
			data.options = [];
			menuGroups.push(data);
			setNewGroupName('');
		})
		.catch(error => console.log("Error detected: " + error));
	}

	const updateNewOptionName = (groupIndex) => (event) => {
		menuGroups[groupIndex].newOptionName = event.currentTarget.value;
		const updatedMenuGroups = menuGroups.map(m => m);
		setMenuGroups(updatedMenuGroups);
	}

	const updateNewOptionWeight = (groupIndex) => (event) => {
		menuGroups[groupIndex].newOptionWeight = event.currentTarget.value;
		const updatedMenuGroups = menuGroups.map(m => m);
		setMenuGroups(updatedMenuGroups);
	}

	const addNewOption = (groupIndex) => (event) => {
		
		const formData = new FormData();
		//formData.append("groupId", event.currentTarget.getAttribute("gid"));
		formData.append("groupId", menuGroups[groupIndex].id);
		formData.append("name", menuGroups[groupIndex].newOptionName);
		formData.append("weight", menuGroups[groupIndex].newOptionWeight);

		fetch('/api/dietprogram/addoption', {
			method: 'POST',
			body: formData
		})
		.then(response => response.json())
			.then(data => {
				menuGroups[groupIndex].options.push(data);
				menuGroups[groupIndex].newOptionName = '';
				menuGroups[groupIndex].newOptionWeight = 0;
				const updatedMenuGroups = menuGroups.map(m => m);
				setMenuGroups(updatedMenuGroups);
		})
		.catch(error => console.log("Error detected: " + error));
	}

	const updateOptionName = (groupIndex, optionIndex) => (event) => {
		menuGroups[groupIndex].options[optionIndex].name = event.currentTarget.value;
		const updatedMenuGroups = menuGroups.map(m => m);
		setMenuGroups(updatedMenuGroups);
	}

	const updateOptionWeight = (groupIndex, optionIndex) => (event) => {
		menuGroups[groupIndex].options[optionIndex].weight = event.currentTarget.value;
		const updatedMenuGroups = menuGroups.map(m => m);
		setMenuGroups(updatedMenuGroups);
	}

	const updateOption = (groupIndex, optionIndex) => (e) => {
		const option = menuGroups[groupIndex].options[optionIndex];
		const formData = new FormData();
		formData.append("id", option.id);
		formData.append("name", option.name);
		formData.append("weight", option.weight);

		fetch('/api/dietprogram/updateoption', {
			method: 'POST',
			body: formData
		})
		.catch(error => console.log("Error detected: " + error));
	}

	const deleteOption = (groupIndex, optionIndex) => (e) => {
		const option = menuGroups[groupIndex].options[optionIndex];
		const updatedMenuGroups = menuGroups.map(m => m);
		updatedMenuGroups[groupIndex].options = updatedMenuGroups[groupIndex].options.filter(item => item.id !== option.id)
		setMenuGroups(updatedMenuGroups);

		const formData = new FormData();
		formData.append("id", option.id);

		fetch('/api/dietprogram/deleteoption', {
			method: 'POST',
			body: formData
		})
		.catch(error => console.log("Error detected: " + error));
	}

	const editGroup = (id) => () => {
		editGroupDialogRef.current.handleShow(id);
	}

	return (
		<div>
			{
				isLoading ?
				(<div>Loading...</div>) :
					(<Table striped bordered hover size="sm">
						<thead>
							<tr>
								<th>Група</th><th>Продукт</th><th>Вага (г)</th><th></th>
							</tr>
						</thead>
						<tbody>
							{menuGroups.map((group, groupIndex) =>
								[
									(<tr key={"mgi" + group.id}>
										<td colSpan="5">
											<a onClick={editGroup(group.id)}>
												{group.name}
												<img width="15px" src="/img/edit.png" />
											</a>
										</td>
									</tr>),
									(
										group.options != null && (
											group.options.map((option, optionIndex) =>
												<tr key={"mgoi" + option.id} id={option.id} >
													<td></td>
													<td>
														<Form.Control placeholder="Продукт" aria-label="Продукт" value={option.name} onChange={updateOptionName(groupIndex, optionIndex)} />
													</td>
													<td>
														<Form.Control placeholder="Вага" aria-label="Вага" type="number" value={option.weight} onChange={updateOptionWeight(groupIndex, optionIndex)} />
													</td>
													<td>
														<Button variant="outline-secondary" onClick={updateOption(groupIndex, optionIndex)}>Оновити</Button>
														<Button variant="outline-secondary" onClick={deleteOption(groupIndex, optionIndex)}>Видалити</Button>
													</td>
												</tr>
											)
										)
									),
									(
										<tr key={"newProduct" + group.id} id={group.id} >
											<td></td>
											<td>
												<Form.Control placeholder="Продукт" aria-label="Продукт" value={group.newOptionName} onChange={updateNewOptionName(groupIndex)} />
											</td>
											<td>
												<Form.Control placeholder="Вага" aria-label="Вага" type="number" value={group.newOptionWeight} onChange={updateNewOptionWeight(groupIndex)} />
											</td>
											<td>
												<Button variant="outline-secondary" gid={group.id} onClick={addNewOption(groupIndex)}>Додати</Button>
											</td>
										</tr>
									)
								]


							)}
							<tr>
								<td colSpan="4">
								</td>
							</tr>
							<tr>
								<td>
								</td>
								<td colSpan="2">
									<Form.Control
										placeholder="Назва групи"
										aria-label="Назва групи"
										aria-describedby="button-add-new-group"
										value={newGroupName}
										onChange={e => setNewGroupName(e.target.value)}
									/>

								</td>
								<td>
									<Button variant="outline-secondary" onClick={addNewGroup}>
										Додати
									</Button>
								</td>
							</tr>
						</tbody>
					</Table>)
			}

			<EditGroupDialog ref={editGroupDialogRef} loadAll={loadAll}></EditGroupDialog>
		</div>
	);
}
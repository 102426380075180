import { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const EditGroupDialog = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState(false);
    const [name, setName] = useState('');

    useImperativeHandle(ref, () => ({
        handleShow(groupId) {
            setId(groupId);
            setShow(true);

            const formData = new FormData();
            formData.append("id", groupId);
            fetch('/api/dietprogram/group/get', {
                method: 'POST',
                body: formData
            }).then(response => response.json()
            ).then(data => {
                setName(data.name);
            }).catch(error => console.log("Error detected: " + error));
        }
    }));

    const handleEdit = () => {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("name", name);

        fetch('/api/dietprogram/group/update', {
            method: 'POST',
            body: formData
        })
        .then(data => {
            //props.loadAll();
            handleClose();
        }).catch(error => {
            console.log("Error detected: " + error);
            //props.loadAll();
            handleClose();
        });
    }

    const handleDelete = () => {
        const confirm = window.confirm("Видалити " + name + "?");
        if (!confirm) return;

        const formData = new FormData();
        formData.append("id", id);
        fetch('/api/dietprogram/group/delete', {
            method: 'POST',
            body: formData
        })
        .then(data => {
            handleClose();
        }).catch(error => {
            console.log("Error detected: " + error);
            handleClose();
        });
    }

    const handleClose = () => {
        props.loadAll();
        setShow(false);
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Назва</Form.Label>
                            <Form.Control placeholder="Група" aria-label="Група" type="text" value={name} onChange={e => setName(e.target.value)} />
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});

export default EditGroupDialog;
import { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const EditIngredientDialog = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState(false);

    const [name, setName] = useState('');
    const [weight, setWeight] = useState('');
    const [targetWeight, setTargetWeight] = useState('');


    useImperativeHandle(ref, () => ({
        handleShow(ingredientId) {
            setId(ingredientId);
            setShow(true);

            const formData = new FormData();
            formData.append("id", ingredientId);
            fetch('/api/dailyreport/dish/ingredient/get', {
                method: 'POST',
                body: formData
            }).then(response => response.json()
            ).then(data => {
                setName(data.name);
                setWeight(data.usedWeight);
                setTargetWeight(data.targetWeight);
            }).catch(error => console.log("Error detected: " + error));
        }
    }));

    const handleClose = () => setShow(false);

    const handleDelete = () => {
        const confirm = window.confirm("Видалити " + name + "?");
        if (!confirm) return;

        const formData = new FormData();
        formData.append("id", id);
        fetch('/api/dailyreport/dish/ingredient/delete', {
            method: 'POST',
            body: formData
        })
        .then(data => {
            props.loadAll();
            handleClose();
        }).catch(error => {
            console.log("Error detected: " + error);
            props.loadAll();
            handleClose();
        });
    }

    const handleEdit = () => {
        try {
            var enteredWeight = Math.round(eval(weight));
            if (!Number.isInteger(enteredWeight)) {
                alert('Щось не так з вагою!');
                return;
            }
        } catch (e) {
            console.log(e.message);
            alert('Щось не так з вагою!');
            return;
        }

        const formData = new FormData();
        formData.append("id", id);
        formData.append("name", name);
        formData.append("usedWeight", enteredWeight);
        
        fetch('/api/dailyreport/dish/ingredient/edit', {
            method: 'POST',
            body: formData
        })
        .then(data => {
            props.loadAll();
            handleClose();
        }).catch(error => {
            console.log("Error detected: " + error);
            props.loadAll();
            handleClose();
        });
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Назва</Form.Label>
                            <Form.Control placeholder="Продукт" aria-label="Продукт" type="text" value={name} onChange={e => setName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Вага (всього {targetWeight})</Form.Label>
                            <Form.Control placeholder="Вага" aria-label="Вага" type="text" value={weight} onChange={e => setWeight(e.target.value)} />
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});

export default EditIngredientDialog;